export const server = 'https://onlyfiesta.club/api';
//export const server = 'http://localhost:3000/api';

export interface ISearchModel {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    like_count: number;
    photo_count: number;
    video_count: number;
    subscribe_cost: number;
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    apath?: string;
    trial?: string;
    advertisement?: string;
}

export const getML = async (search: string): Promise<ISearchModel[]> => {
    const r = await fetch(
        `${server}/search/ml${search}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } }
    );

    if (!r.ok) {
        let message = r.statusText;

        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch { }

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel[];
    return m;
}

export const getMS = async (id: string): Promise<ISearchModel> => {
    const r = await fetch(
        `${server}/search/ms/${id}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } }
    );

    if (!r.ok) {
        let message = r.statusText;

        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch { }

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel;
    return m;
}

export const getMA = async (id: string): Promise<ISearchModel> => {
    const r = await fetch(
        `${server}/search/ma/${id}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } }
    );

    if (!r.ok) {
        let message = r.statusText;

        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch { }

        throw new Error(message);
    }

    const m = await r.json() as ISearchModel;
    return m;
}

let link: { hostname?: string, jwt?: string } | undefined = undefined;

export const setLink = (referrer?: string, jwt?: string) => {
    if (!!link) {
        return;
    }

    link = {};

    if (!referrer) {
        return;
    }

    try {
        const url = new URL(referrer);
        if (!url.hostname) {
            return
        }

        link.hostname = url.hostname;

        if (!jwt) {
            return;
        }

        link.jwt = jwt;
    }
    catch { }
}

export const trackVisit = async (target: string, model_a_id?: string, model_s_id?: string): Promise<void> => {
    if (!link || !link.hostname) {
        return;
    }

    const body = { domain: link.hostname, target: target };
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }
    if (!!link.jwt) {
        body['jwt'] = link.jwt;
    }

    await fetch(
        `${server}/tracking/visit`,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    );
}

export const trackView = async (model_a_id?: string, model_s_id?: string): Promise<void> => {
    const body = {};
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }
    if (!!link && !!link.jwt) {
        body['jwt'] = link.jwt;
    }

    await fetch(
        `${server}/tracking/view`,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    );
}

export const trackClick = async (model_a_id?: string, model_s_id?: string): Promise<void> => {
    const body = {};
    if (!!model_a_id) {
        body['model_a_id'] = model_a_id;
    }
    if (!!model_s_id) {
        body['model_s_id'] = model_s_id;
    }
    if (!!link && !!link.jwt) {
        body['jwt'] = link.jwt;
    }

    await fetch(
        `${server}/tracking/click`,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    );
}