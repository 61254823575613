import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Header } from '../component/Header';
import { ModelList } from '../component/ModelList';
import { ISearchModel, getML, setLink, trackVisit } from '../api';


export const ML = () => {
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();

    const [seed, setSeed] = useState<number>(Date.now());

    const [modelList, setModelList] = useState<ISearchModel[]>([]);

    const [needUpdate, setNeedUpdate] = useState<boolean>(true);
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    useEffect(() => {
        const work = async () => {
            try {
                const jwt = searchParams.get('jwt');
                if (!!jwt) {
                    const csp = new URLSearchParams(searchParams.toString());
                    csp.delete('jwt');
                    setSearchParams(new URLSearchParams(csp.toString()))
                }

                setLink(document.referrer, !!jwt ? jwt : undefined);

                await trackVisit('/search/ml', undefined, undefined);
            }
            catch { }
        }

        work();
    }, []);

    useEffect(() => {
        const scrollHand = (e) => {
            if (needUpdate || updateInProgress) {
                return;
            }

            if (e.target.documentElement.scrollHeight - e.target.documentElement.scrollTop < 3 * window.innerHeight) {
                setNeedUpdate(true);
            }
        }

        document.addEventListener('scroll', scrollHand);

        return () => document.removeEventListener('scroll', scrollHand);
    }, [needUpdate, updateInProgress]);

    useEffect(() => {
        const touchmoveHand = (e) => {
            if (modelList.length == 0 || needUpdate || updateInProgress) {
                return;
            }

            if (window.innerHeight * modelList.length - e.target.offsetTop < 6 * window.innerHeight) {
                setNeedUpdate(true);
            }
        }

        document.addEventListener('touchmove', touchmoveHand);

        return () => document.removeEventListener('touchmove', touchmoveHand);
    }, [modelList, needUpdate, updateInProgress]);

    useEffect(() => {
        window.scrollTo(0, 0);

        setSeed(Date.now());

        setModelList([]);

        setNeedUpdate(true);
        setUpdateInProgress(false);
    }, [location]);

    useEffect(() => {
        if (!needUpdate || updateInProgress) {
            return;
        }

        const work = async () => {
            setUpdateInProgress(true);

            try {
                const r = await getML(`${location.search}&limit=${12}&offset=${modelList.length}&seed=${seed}`);
                setModelList([...modelList, ...r]);
            }
            catch (error) {
                console.error(error);
                alert(String(error));
            }

            setNeedUpdate(false);
            setUpdateInProgress(false);
        }

        work();
    }, [location, seed, modelList, needUpdate, updateInProgress])

    return (
        <div className='wrapper'>
            <Header />
            <main className='page-model'>
                <ModelList modelList={modelList} />
            </main>
        </div>
    );
}