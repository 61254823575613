import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Header } from '../component/Header';
import { Model } from '../component/Model';
import { ISearchModel, getMA, setLink, trackVisit } from '../api';


export const MA = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [model, setModel] = useState<ISearchModel>(null);

    useEffect(() => {
        const work = async () => {
            try {
                const jwt = searchParams.get('jwt');
                if (!!jwt) {
                    const csp = new URLSearchParams(searchParams.toString());
                    csp.delete('jwt');
                    setSearchParams(new URLSearchParams(csp.toString()))
                }

                setLink(document.referrer, !!jwt ? jwt : undefined);

                const id = searchParams.get('id');
                if (!id) {
                    alert('Query param [id] must be');
                    return;
                }

                await trackVisit('/search/ma', undefined, id);

                try {
                    const r = await getMA(id);
                    if (!r) {
                        alert('Model not found');
                        return;
                    }

                    setModel(r);
                }
                catch {
                    alert('Something wrong');
                    return;
                }
            }
            catch { }
        }

        work();
    }, []);

    return (
        <div className='wrapper'>
            <Header />
            <main className='page-model'>
                {
                    !!model &&
                    <Model model={model}/>
                }
            </main>
        </div>
    );
}