import React, { useEffect, useRef, useState } from 'react';


const ArrowSvg = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path d='M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z' />
        </svg>
    );
}

const CrossSvg = () => {
    return (
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <rect width='24' height='24' fill='white' />
                <path d='M7 17L16.8995 7.10051' stroke='#71767a' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M7 7.00001L16.8995 16.8995' stroke='#71767a' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </svg>
    );
}

interface IOptionItemProperties {
    name: string;
    onDelete: () => void;
}

const OptionItem = ({ name, onDelete }: IOptionItemProperties) => {
    return (
        <span
            className='selected-options__item'
            onClick={(e) => {
                e.stopPropagation();
                onDelete();
            }}
        >
            <span className='selected-options__tag'>
                {name}
            </span>
            <span className='selected-options__remove'>
                <CrossSvg />
            </span>
        </span>
    );
}

interface IOptionDropDownProperties {
    name: string;
    values: string[];
    onPick: (v: string) => void;
}

const OptionDropDown = ({ name, values, onPick }: IOptionDropDownProperties) => {
    const [drop, turnDrop] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>();
    useEffect(() => {
        const work = (event) => {
            if (!ref || ref.current.contains(event['target'])) {
                return;
            }

            turnDrop(false);
        }

        document.addEventListener('click', work);

        return () => document.removeEventListener('click', work);
    }, []);

    return (
        <div ref={ref} className='options__drop-down'>
            <div onClick={() => turnDrop(!drop)} className='options__header'>
                <span className='options__title'>{name}</span>
            </div>
            <div className={`options__body ${drop ? 'drop' : ''}`}>
                {values.map((v) => <div onClick={() => { turnDrop(false); onPick(v); }} className='options__option'>{v}</div>)}
            </div>
        </div>
    );
}

interface ICategoriesProperties {
    isActive: boolean;
    categories: [string, string][];
    onChange: (c: [string, string][]) => void;
}

export const Categories = ({ isActive, categories, onChange }: ICategoriesProperties) => {
    const data: [string, string[]][] = [
        ['gender', ['female', 'male', 'transgender', 'couple']],
        ['subscription', ['paid', 'free']],
        ['body', ['athletic', 'chubby', 'curvy', 'fat', 'muscle', 'normal', 'skinny']],
        ['skin', ['black', 'brown', 'caramel', 'tan', 'white']],
        ['hair', ['blonde', 'brown', 'brunette', 'red']],
        ['eye', ['blue', 'brown', 'green']],
        ['ethnic', ['arabic', 'asian', 'ebony', 'european', 'latino']],
    ];

    const [open, turnOpen] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const work = (event) => {
            if (!ref || ref.current.contains(event['target'])) {
                return;
            }

            turnOpen(false);
        }

        document.addEventListener('click', work);

        return () => document.removeEventListener('click', work);
    }, []);

    return (
        <div ref={ref} className={'links__item item-link' + ((isActive) ? ' chosen' : '')}>
            <div className='item-link__custom-select custom-select'>
                <div className={`custom-select__body ${open ? 'opened' : ''}`}>
                    <div onClick={() => turnOpen(!open)} className='custom-select__select-box select-box'>
                        <div className='select-box__selected-options placeholder'>
                            {`Tags: ${categories.length}`}
                        </div>
                        <div className='select-box__arrow'>
                            <ArrowSvg />
                        </div>
                    </div>
                    <div className='custom-select__options options'>
                        <div className='options__selected selected-options'>
                            {
                                !!categories.length &&
                                categories.map((c, i) => {
                                    return (
                                        <OptionItem
                                            key={`OptionItem${i}`}
                                            name={`${c[0]} - ${c[1]}`}
                                            onDelete={() => onChange(categories.filter((cc) => c[0] != cc[0]))}
                                        />
                                    );
                                })
                            }
                        </div>
                        {
                            categories.length < data.length &&
                            data.filter((d) => categories.every((c) => d[0] != c[0])).map((d, i) => {
                                return (
                                    <OptionDropDown
                                        key={`OptionDropDown${i}`}
                                        name={d[0]}
                                        values={d[1]}
                                        onPick={(v: string) => onChange([...categories, [d[0], v]])}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}